import { Section, Social } from "./utils"

export const sections: Section[] = [
  {
    rootLink: "/comite-regional/",
    link: "/comite-regional/equipe/",
    name: "Le Comité Régional",
    index: false,
  },
  {
    link: "/blog/",
    name: "Actus",
    index: false,
  },
  {
    rootLink: "/vie-sportive/",
    link: "/vie-sportive/calendrier/",
    name: "Vie sportive",
    index: false,
  },
  {
    rootLink: "/formations/",
    link: "/formations/",
    name: "Formations",
    index: false,
  },
  // {
  //   rootLink: "/stages/",
  //   link: "/stages/",
  //   name: "Stages",
  //   index: false,
  // },
  {
    link: "/clubs/",
    name: "Clubs",
    index: false,
  },
  {
    link: "/newsletter/",
    name: "Flèch'Infos",
    index: false,
  },
]

export const socials: Social[] = [
  {
    link: "https://www.facebook.com/CRTA.GdEst/",
    icon: "icon-facebook",
  },
  {
    link: "https://twitter.com/CRTA_GrandEst",
    icon: "icon-twitter",
  },
  {
    link: "mailto:contact@tiralarc-grand-est.fr",
    icon: "icon-mail",
  },
]
